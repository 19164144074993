import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
// import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import Img from "gatsby-image";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import background3 from '../images/3.png';
import { GoogleMap, LoadScript, InfoWindow, Marker } from "@react-google-maps/api";

const flag = typeof window !== 'undefined';

let jQuery;
let windowInstance;
let documentInstance;
if (flag) {
    jQuery = require('jquery');
    windowInstance = window;
    documentInstance = document;
}

const styles = () => ({
  root: {
    fontWeight: "bold",
    // backgroundColor: '#000'
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff'
    }
  },
});

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 35.705025,
  lng: 139.7582,
};

const divStyle = {
  background: "white",
  fontSize: 7.5,
};

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

class About extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      seo: false,
      // scrollTopFlag: false,
      windowHeight: 0,
      siteHeight: 0,
      divTop: {},
      divHeight: {}
    }

    var url = this.props.location.href
    if (typeof(url) === 'string') {
      if (url.indexOf('staging') !== -1) {
        // console.log(this.state.disabled)
        this.state.disabled = true
        // console.log(this.state.disabled)
      } else {
        this.state.seo = true
      }
      // console.log(this.state.seo)
    }
    // console.log(this.props.data.testFixed)
    // authIP(this)
  }

  async componentDidMount() {
    windowInstance.addEventListener('scroll', this.handleScroll);
    if (this.state.disabled) {
      var res = await fetch('/api/authIP', {
        method: 'POST'
      }).then((response) => {
        // console.log(response)
        return response.text();
      });
      // console.log(res)
      if (res === 'Y') {
        // console.log(state.state.disabled)
        this.setState({disabled: false});
      }
      // console.log(state.state.disabled)
    }
    // console.log(jQuery('div.gatsby-image-wrapper'))
    jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
    jQuery('div.gatsby-image-wrapper').css('width', '');
    jQuery('div.gatsby-image-wrapper').css('position', '');

    var insertDiv = {}
    var insertHeight = {}
    jQuery('div.animation-init').each(function() {
      insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
      insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
      // console.log(jQuery(this).attr('id'));
      // console.log(jQuery(this).offset().top);
    });


    this.state.divTop = insertDiv
    this.state.divHeight = insertHeight
    // console.log(Object.keys(this.state.divTop))
    // console.log(this.state.divHeight['animation-2-right-title']);
    this.handleScroll();
  }

  renderSeo() {
    return (
      <Seo title="About">
        <meta
          name="description"
          content="AristoBrain株式会社の会社概要、メンバー出身大学、弊社が関わった案件、所在地"
        />
        <meta
          name="keywords"
          content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,データ分析,IoT,AI,実績"
        />
      </Seo>
    )
  }

  handleScroll = () => {
    // if (this.state.scrollTopFlag) {

    var insertDic = {};
    var insertDiv = {};
    var insertHeight = {};
    var predivTop = Object.keys(this.state.divTop);
    // console.log(predivTop);
    if (this.state.siteHeight !== documentInstance.body.clientHeight) {
      insertDic['siteHeight'] = documentInstance.body.clientHeight;
      insertDic['windowHeight'] = windowInstance.innerHeight;
      jQuery('div.animation-init').each(function() {
        if (predivTop.includes(jQuery(this).attr('id'))) {
          insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
          insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
          // console.log(jQuery(this).attr('id'));
          // console.log(jQuery(this).offset().top);
        }
      });
    }

    // var ua = navigator.userAgent;
    // console.log(ua);
    var top = windowInstance.scrollY;
    // // Edge, Safariの場合 
    // if(ua.indexOf('Edge') !== -1 || (windowInstance.chrome || 'WebkitAppearance' in documentInstance.documentElement.style)) {
    //   top = jQuery('body').scrollTop();
    // // Chrome, IE, Firefoxの場合
    // } else {
    //   top = jQuery('html').scrollTop();
    // }
    // console.log(top);
    // console.log(windowInstance.innerHeight)
    // console.log(insertDic);
    if (Object.keys(insertDic).length > 0) {
      if (Object.keys(insertDiv).length > 0) {
        insertDic['divTop'] = insertDiv
        insertDic['divHeight'] = insertHeight
      }
      this.setState(insertDic)
      // console.log(this.state.divTop)
      // console.log(this.state)
    }

    // console.log(top + this.state.windowHeight);
    // console.log(this.state.divTop['animation-2-right-title']);
    // console.log(top);
    // console.log(this.state.divTop['animation-2-right-title'] + this.state.divHeight['animation-2-right-title']);

    var dic = this.state;
    predivTop.forEach(function(key) {
      // var direction, elem;
      // [direction, elem] = key.split('-').slice(2);
      // console.log(direction)
      // console.log(elem)
      // console.log(top + dic.windowHeight);
      // console.log(dic.divTop[key]);
      // console.log(top);
      // console.log(dic.divTop[key] + dic.divHeight[key]);
      if ((top + dic.windowHeight >= dic.divTop[key]) && (top <= dic.divTop[key] + dic.divHeight[key])) {
        if (key === 'about-1') {
          jQuery('div#' + key).addClass('fade-in-about-image');
          jQuery('div#' + key + ' > div > div > h1').addClass('fade-in-about');
          jQuery('div#' + key + ' > div > div.under-bar-text').addClass('fade-in-about-text');
        } else if (key === 'about-2') {
          jQuery('div#' + key).css('opacity', 1);
          jQuery('div#' + key + ' div > div.introduction-logo').addClass('fade-in-left-table');
          jQuery('div#' + key + ' div > div.profile-table > table#about-left-table').addClass('fade-in-left-table');
          jQuery('div#' + key + ' div > div.profile-table > table#about-center-table').addClass('fade-in-center-table');
          jQuery('div#' + key + ' div > div.profile-table > table#about-right-table').addClass('fade-in-right-table');
        } else if (key === 'about-3') {
          jQuery('div#' + key).css('opacity', 1);
          jQuery('div#' + key).addClass('fade-in-google-map');
        }
        // console.log(top + dic.windowHeight);
        // console.log(dic.divTop[key]);
        // console.log(top);
        // console.log(dic.divTop[key] + dic.divHeight[key]);
        delete dic.divTop[key];
        delete dic.divHeight[key];
      }
    });

    this.setState(dic);

    // insertDic['divTop'] = insertDiv
    // this.setState(insertDic)
    // console.log(this.state.siteHeight)
    // console.log(this.state.windowHeight)
    // console.log(this.state.divTop)
    // console.log(windowInstance.scrollY)



    // } else {
    //   this.setState({scrollTopFlag: true});
    // }
  }


  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      return (
        <Page url={this.props.location.pathname}>
          <ThemeProvider theme={theme}>
            {this.state.seo && this.renderSeo()}
            <Grid
              container
              direction='row'
              justify="space-between"
              spacing={0}
              alignItems='stretch'
            >
              <Grid xs={12} id='about-1' className='animation-init' style={{backgroundImage: `url(${background3})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div style={{margin: 0, padding: 0}}>
                  <div className='under-bar-title'>
                    <h1>About</h1>
                    <p>
                      会社概要
                    </p>
                  </div>
                  <div className='under-bar-text'>
                    <p className='thread-text'>生命科学、機械学習の知見をコアに、<br />新しいサービスの開発を進めています</p>
                    <h2>メンバー出身大学</h2>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: 0}}>・東京大学理学部生物学科</p>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: 0}}>・金沢大学理工学域電子情報学類</p>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: 0}}>・琉球大学工学部情報工学科</p>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: 0}}>・東京大学教育学部教育実践・政策学コース</p>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: 0}}>・東京大学工学部計数工学科</p>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: 0}}>・東京大学医学部医学科</p>
                    <h2>弊社が関わった案件</h2>
                    <p className='tbody-text' style={{marginTop: 0, marginBottom: '15%'}}>がんの変異推定 / 商品推薦 / データサイエンス教育 / BtoB マッチング / 求人文章仕分け AI / IoT センサデータ用 AI / 再生医療用 AI / 各種保険用 AI / がんの腫瘍径測定 AI（※現在進行中のものも含みます）</p>
                  </div>
                </div>
                {/* <HomeFeatures /> */}
              </Grid>
              <Grid xs={12} id='about-2' className='animation-init' style={{background: '#000000', textAlign: 'center'}}>
                <div className='center-plate'>
                  <Img
                    fixed={this.props.data.testFixed.childImageSharp.fixed}
                    alt="AristoBrain株式会社"
                    // style={{height: '4vw', width: '12vw', maxWidth: 200, maxHeight: 140, minWidth: 100, minHeight: 35}}
                    className='introduction-logo'
                    imgStyle={{width: '100%', height: 'auto'}}
                  />
                  <div className='profile-table'>
                    <table id='about-left-table'>
                      <tr className='thread-text'>
                        AristoBrain 株式会社
                      </tr>
                      <tr className='tbody-text'>
                        設立：2017年12月15日
                      </tr>
                      <tr className='tbody-text'>
                        法人番号：2011101082635
                      </tr>
                      <tr className='tbody-text'>
                        住所：東京都文京区本郷２丁目18-9<br />
                        ドームヒルズ本郷 3-A
                      </tr>
                      <tr className='tbody-text'>
                        電話番号：03-5801-0966
                      </tr>
                      <tr className='tbody-text'>
                        Email:contact@aristobrain.com
                      </tr>
                      <tr>
                        
                      </tr>
                    </table>
                    <table id='about-center-table'>
                      <tr className='tbody-text'>
                        代表取締役
                      </tr>
                      <tr className='thread-text'>
                        鈴木瑞人
                      </tr>
                      <tr className='tbody-text'>
                        Mizuto SUZUKI, CEO
                      </tr>
                      <tr className='tbody-text'>
                        東京大学理学部生物学科卒業
                      </tr>
                      <tr className='tbody-text'>
                        東京大学大学院新領域創成科学研究科修士課程終了
                      </tr>
                      <tr className='tbody-text'>
                      </tr>
                      <td>

                      </td>
                    </table>
                    <table id='about-right-table'>
                      <tr className='tbody-text'>
                        取締役
                      </tr>
                      <tr className='thread-text'>
                        西野卓也
                      </tr>
                      <tr className='tbody-text'>
                        Takuya NISHINO, CTO
                      </tr>
                      <tr className='tbody-text'>
                        金沢大学工学科機域電子情報学類生命情報コース終了
                      </tr>
                      <tr className='tbody-text'>
                        セキュリティキャンプ卒業生
                      </tr>
                      <tr className='tbody-text'>
                        認定スクラムマスタセキュリティエンジニア
                      </tr>
                      <tr className='tbody-text'>
                        兼データサイエンティスト
                      </tr>
                    </table>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} id='about-3' className='animation-init' style={{height: '30vw'}}>
                <LoadScript googleMapsApiKey="AIzaSyDzcb4H5ye37Ab5LjoGQ-fanT4fzzvbYY4">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={17}
                  >
                    <InfoWindow position={center}>
                      <div style={divStyle}>
                        <h1>AristoBrain本社</h1>
                      </div>
                    </InfoWindow>
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Page>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    testFixed: file(relativePath: { eq: "AristoBrain株式会社.png" }) {
      childImageSharp {
        fixed(width: 150) {
            ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(About));
